import axios from 'axios';

export const logSeverity = {
	normal: 0,
	warning: 1,
	error: 2,
}

export const logEvents = async (severity, source, logText) => {
    // TODO: send to the db
    console.log('EventLog >>', severity, source, logText);
}

export const getParentCode = (code) => {
	let treeCode = "";
	if (code) treeCode = code;
	if (treeCode==="" || treeCode.length <= 3) return "";	// root node
	return treeCode.slice(0, treeCode.length - 4);
}

export const getData = async (url, params, headers) => {
	let retValue;

	await axios
		.get(url, {params}, {headers})
		.then(res => {
			// return the res object
			retValue = res;
		})
		.catch(err => {
			retValue = {status: -1, statusText: err, data: null};
			logEvents(logSeverity.error, `getData: ${url}`, {err, params, headers});
		});

	return retValue;
};

export const postData = async (url, bodyData, headers) => {
	let retValue;
	let bodyFormData = new FormData();

	for (let [key, value] of Object.entries(bodyData)) {
		bodyFormData.append(key, value);
	}

	await axios
		.post(url, bodyData, {
			headers,
		})
		.then(res => {
			retValue = res;
		})
		.catch(err => {
			retValue = {status: -1, statusText: err, data: null};
			logEvents(logSeverity.error, `postData ${url}`, {err, bodyData, headers});
		});

	return retValue;
};

export const deleteData = async (url, bodyData, headers) => {
	let retValue;
	let bodyFormData = new FormData();

	for (let [key, value] of Object.entries(bodyData)) {
		bodyFormData.append(key, value);
	}

	await axios
		.delete(
			url,
			{data: bodyData},
			{
				headers,
			}
		)
		.then(res => {
			retValue = res;
		})
		.catch(err => {
			retValue = {status: -1, statusText: err, data: null};
			logEvents(logSeverity.error, `deleteData: ${url}`, {err, bodyData, headers});
		});

	return retValue;
};


import useAuth from 'app/hooks/useAuth'
import React, { useState, useEffect, useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import AppContext from '../contexts/AppContext'

const getUserRoleAuthStatus = (pathname, user, routes) => {
    const matched = routes?.find((r) => r.path === pathname)
    
    const authenticated =
        matched?.auth?.length 
            ? matched.auth.includes(user?.role)
            : true
    return authenticated
}

const AuthGuard = ({ children }) => {
    const { isAuthenticated, user } = useAuth()

    const [previousRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()

    const { routes } = useContext(AppContext);

    const isUserRoleAuthenticated = getUserRoleAuthStatus(
        pathname,
        user,
        routes
    )
    let authenticated = isAuthenticated && isUserRoleAuthenticated

    useEffect(() => {
        if (previousRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previousRoute])

    if (authenticated) return <>{children}</>
    else {
        return (
            <Navigate
                to="/session/login"
                state={{ redirectUrl: previousRoute }}
            />
        )
    }
}

export default AuthGuard

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
import { authRoles } from 'app/auth/authRoles';

const Categories = Loadable(lazy(() => import("./Categories")));
const UploadQuestions = Loadable(lazy(() => import("./UploadQuestions")));
const Questions = Loadable(lazy(() => import("./Questions")));

const adminRoutes = [
    {
        path: '/admin/categories',
        element: <Categories />,
        //TODO: activate roles
        // auth: authRoles.admin,
    },
    {
        path: '/admin/upload-questions',
        element: <UploadQuestions />,
        // auth: authRoles.admin,
    },
    {
        path: '/admin/questions-maintenance',
        element: <Questions />,
        // auth: authRoles.admin,
    },
    {
        path: '/admin',
        element: <></>,
        auth: authRoles.admin,
    },
]

export default adminRoutes

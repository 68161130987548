import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { postData } from 'app/utils/funcs'
import SnackbarUtils from 'app/components/SnackbarUtils'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
			const url = encodeURI(process.env.REACT_APP_API_BASE_URL + 'auth/login');
			const basicAuth = 'Basic ' + btoa(email + process.env.REACT_APP_B64_SEPARATOR + password);
			const headers = {
					Authorization: basicAuth,
					'Content-Type': 'application/json',
			};
			const authResponse = await postData(url, {}, headers);

			if (authResponse.status === 200) {
				const accessToken = authResponse.data.auth_key;
				const user  = {
					email: authResponse.data.email,
					name: authResponse.data.first_name + ' ' + authResponse.data.last_name,
					role: (authResponse.data.is_admin) ? 'ADMIN' : 'GUEST',
				};

				SnackbarUtils.success(`Welcome back ${user.name}`);

				setSession(accessToken);

				dispatch({
						type: 'LOGIN',
						payload: {
								user,
						},
				});
			} else {
				SnackbarUtils.error('Unauthorized or Error in login process, please try again');
			}

    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)
                    // TODO: change this API to the proper one to handle the saved accessToken
                    const response = await axios.get('/api/auth/profile')
                    const { user } = response.data

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.log('Access Token >>>', err)
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
			<AuthContext.Provider
					value={{
							...state,
							method: 'JWT',
							login,
							logout,
							register,
					}}
			>
				{children}
			</AuthContext.Provider>
    )
}

export default AuthContext

import '../fake-db'
import React, { useEffect } from 'react'
import { Store } from './redux/Store'
import { Provider } from 'react-redux';
import { AllPages } from './routes/routes';
import { MatxTheme } from 'app/components';
import { useRoutes } from 'react-router-dom';
import { AuthProvider } from 'app/contexts/JWTAuthContext';
import { SettingsProvider } from 'app/contexts/SettingsContext';
import { useSnackbar } from 'notistack';
import SnackbarUtils from './components/SnackbarUtils';
import AppContext from './contexts/AppContext';

const App = () => {
	const allPages = useRoutes(AllPages());
	const { enqueueSnackbar, closeSnackbar} = useSnackbar();
		
	useEffect(()=> {
			SnackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar);
	}, [enqueueSnackbar, closeSnackbar]);

	return (
		<AppContext.Provider value={{routes: allPages.props.match.route.children}}>
			<Provider store={Store}>
				<SettingsProvider>
						<MatxTheme>
								<AuthProvider>
										{allPages}
								</AuthProvider>
						</MatxTheme>
				</SettingsProvider>
			</Provider>
		</AppContext.Provider>
	)
}

export default App
